@import '~ethos-design-system/src/components/Colors.scss';
@import '~ethos-design-system/src/components/Media/Media.scss';

$accordion-border-color: rgba(0, 0, 0, 0.08);

.resourceTabsWrapper {
  padding-left: 32px;
  float: left;
}

.headingContainer {
  background-color: white;
  position: fixed;
  width: 100%;
}

.tabsNavContainer {
  height: auto;
  padding: 0.25rem 20px 24px 1px;
  white-space: nowrap;
  display: inline-flex;
  width: 100%;
  margin-bottom: 24px;
  overflow: hidden;
}

.tabsNavWrapper {
  display: flex;
  flex-direction: row;
  left: 0;
  position: relative;
  overflow-x: scroll;
}

.paneContainer {
  @media (max-width: 767px) {
    width: 100%;
  }
  padding: 24px;
  width: 640px;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.25rem;
  border-color: rgba(194, 194, 194, 1);
  margin-bottom: 37px;
}

.entryContainer {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  justify-content: space-between;
  border-top: 1px solid;
  border-color: rgba(194, 194, 194, 0.4);
}

.entryTagsContainer {
  display: flex;
  flex-direction: row;
  min-width: fit-content;
}

.entryTag {
  background-color: var(--BrandDuckegg);
  border-radius: 18px;
  height: 23px;
  padding: 4px 8px 2px;
  margin-left: 5px;
  text-wrap: nowrap;
  min-width: fit-content;
}

.entryRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &.top {
    margin-bottom: 16px;
  }
}

.entryRowEstimatorIntro {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.top {
    margin-bottom: 16px;
  }
}

.entryRowWidget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.top {
    margin-bottom: 16px;
  }
}

.entrySubtitle {
  max-width: 337px;
  padding-right: 32px;
  color: var(--GraySecondary--translucent);
}

.entrySubtitleEstimatorIntro {
  padding-right: 16px;
  padding-bottom: 12px;
  color: var(--GraySecondary--translucent);
}

.entrySubtitleCodeSnippet {
  resize: none;
  padding: 16px 16px;
  border: solid 1px $accordion-border-color;
  border-radius: 0.25rem;
  background-color: var(--GrayLightHover--translucent);
  color: var(--GraySecondary--translucent);
  margin-bottom: 12px;
}

.entryCTA {
  align-self: flex-end;
}

.entryCTAtext {
  padding-bottom: 12px;
  height: auto;
  border-bottom: 1px solid;
  border-color: rgba(194, 194, 194, 1);
  text-wrap: nowrap;
  min-width: max-content;
}

.entryCTAButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding-bottom: 12px;
  height: auto;
  border-bottom: 1px solid;
  border-color: rgba(194, 194, 194, 1);
  text-wrap: nowrap;
  min-width: max-content;
}

.entrySubtitleLink {
  font-weight: 500;
}

.root {
  position: relative;
  transition: opacity 0.5s ease;

  .container {
    width: 100%;
    max-width: var(--ContainerMaxWidth);
    margin: 0 auto;
  }

  @include for-laptop-and-up {
    padding-top: var(--Space-32);
    padding-bottom: var(--Space-48);
  }

  &.loaded {
    opacity: 1;
  }

  &:not(.loaded) {
    min-height: 100vh;
    opacity: 0;
  }
}

@include for-phone-only {
  .HeadingMobile {
    padding-bottom: var(--Space-8);
    padding-top: var(--Space-12);
  }
}

@include for-tablet-only {
  .HeadingMobile {
    padding-bottom: var(--Space-8);
    padding-top: var(--Space-12);
  }
}

@include for-phone-and-tablet {
  .TabPanel.Selected {
    border-bottom: solid 1px $accordion-border-color;
  }

  .HeadingMobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .AccordionIcon {
    display: inline;
    padding-left: var(--Space-80);
    margin-bottom: 10px;
    z-index: -1;
  }
}

.Nav {
  border-bottom: 1px solid var(--GrayStrokeAndDisabled--translucent);

  .container {
    display: flex;
  }
}

.NavItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
  border-radius: 18px;
  height: 48px;
  color: var(--GraySecondary--translucent);

  .Label {
    font-weight: 500;
    font-size: 24px;
    :hover {
      background-color: var(--BrandMoss);
    }
  }

  &.Selected {
    background-color: var(--BrandMoss);
    color: var(--BrandForest);
  }

  &:hover {
    color: var(--BrandForest);
    background-color: rgba(219, 237, 229, 0.5);
    &.Selected {
      background-color: var(--BrandMoss);
    }
  }
}

.Tabs {
  padding-top: 240px;
  .TabPanel {
    display: none;

    &.Selected {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  }
}

.HeadingMobile {
  cursor: pointer;

  @include for-laptop-and-up {
    display: none;
  }
}

.AccordionIcon {
  @include for-laptop-and-up {
    display: none;
  }
}

.TitleMedium2.Sans.Medium500 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@include for-laptop-and-up {
  .Pane.entries {
    display: block;
    padding-top: var(--Space-8);
  }
}

@include for-phone-and-tablet {
  .Pane.entries {
    display: none;
  }

  .Pane.Selected.entries {
    display: block;
  }

  .Pane.Selected {
    .AccordionIcon {
      transform: scale(1, -1);
    }
    .HeadingMobile {
      display: none;
    }
  }
  .Tabs {
    padding-top: 200px;
  }
}

.paneTitle {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
