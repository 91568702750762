@import '~ethos-design-system/src/components/Media/Media.scss';

.mobile {
  @include for-laptop-and-up {
    display: none;
  }
}

.desktop {
  display: none;
  @include for-laptop-and-up {
    display: block;
  }
}
